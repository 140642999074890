import { render, staticRenderFns } from "./pageSpeed.vue?vue&type=template&id=c05bd532&scoped=true&"
import script from "../assets/js/pagespeed.js?vue&type=script&lang=js&"
export * from "../assets/js/pagespeed.js?vue&type=script&lang=js&"
import style0 from "../assets/css/pagespeedtech.css?vue&type=style&index=0&id=c05bd532&lang=css&scoped=true&"
import style1 from "../assets/css/pagespeedt.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c05bd532",
  null
  
)

export default component.exports