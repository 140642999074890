/* eslint-disable */
import axios from 'axios'
import SNotification from "../../components/SNotification"
import Smodal from "../../components/Smodal"

export default {
    name: "pageSpeed",
    components: {
        SNotification,
        Smodal
    },
    computed:{
        lang(){
            return this.$root.lang;
        },
        beforeComputed(){
            return this.page.before;
        }
    },
    data: function() {
        return  {
            ll:null,
            page: {
                before:null,
                input: null,
                desktop: null,
                mobile: null,
                errorMsg: null
            },
            domain: null,
            domainBefore:null,
            isRedirect: false,
            showProgress: false,
            dateGenerate: null,
            notStart: true,
            isAnalyze: false,
            result:{
                desktop: null,
                mobile: null
            },
            textPercent:{
                desktop: null,
                mobile: null
            },
            errorBanner: false,
            mode: 'desktop',
            modeRev: 'mobile',
            cach: false,
            firstArr:[],
            ErrorArr:[],
            SuccessArr:[],
            ErrorCount: 0,
            SuccessCount: 0,
            pageLoadTime: 0,
            auditRefsFirst:[
                /*'first-contentful-paint',
                'first-meaningful-paint',
                'speed-index',
                'first-cpu-idle',
                'interactive',
                'max-potential-fid'*/
            ],
            SuccessArrKeys:[
                'user-timings',
                'non-composited-animations'
            ],
            categ:[],
            tabScore:1,
            tabScan:0,
            color:['rgb(155, 155, 155)', 'rgb(251, 104, 104)', 'rgb(252, 148, 44)','rgb(42, 201, 161)'], //  'rgb(12, 206, 107)'
            arr:null,
            progress:0,
            percent: 0,
            percentC: 628.3,
            percentColor: 'rgb(155, 155, 155)',
            idProject: null,
            smodal:{
                show: false,
                text:null
            },
            llg:{
                'cumulative-layout-shift':7682,
                'diagnostics':[7683,7684],
                'final-screenshot':[7685,7686],
                'first-contentful-paint':7687,
                'interactive':7688,
                'large-javascript-libraries':[7689,7690],
                'largest-contentful-paint':7691,
                'main-thread-tasks':[7692,7693],
                'metrics':[7694,7695],
                'network-requests':[7696,7697],
                'non-composited-animations':[7698,7699],
                'screenshot-thumbnailsrandom':[7700,7721],
                'speed-index':7722,
                'total-blocking-time':7723
            },
            idpage: {
                desktop:0,
                mobile:0,
                rand:0
            },
            linkApi:'',
            whiteLabel:{
                logo:'https://semalt.com/img/_reports/s/semalt_logo.png',
                footer:'Powered by SEMALT: www.semalt.com'
            }
        }
    },
    methods: {
        async init() {
            //this.route();
            await this.getll();
            await this.main();
        },
        async getll() {
            let res = await axios.post('/api/main/get',{datall: {"lang": this.lang,"controller": ["page-speed","header-footer"] } } );
            if (res && res.data && res.data.datall) {
                this.ll = res.data.datall;
            }
        },
        async main() {
            if(this.$route.query){
                if(this.$route.query.url) this.page.before = this.page.input = decodeURIComponent(this.$route.query.url);
                else {
                    this.isAnalyze = false;
                    return;
                }
                if(this.$route.query.mode && this.$route.query.mode =='mobile') this.mode = 'mobile';
                else this.mode = 'desktop';

                if(this.$route.query.id && parseInt(this.$route.query.id)>0 ) this.idProject = parseInt(this.$route.query.id);

                this.start(false);
            }
        },
        clearView(){
            this.percent = 0;
            this.auditRefsFirst = [];
            this.firstArr = [];
            this.ErrorArr = [];
            this.SuccessArr = [];
            this.ErrorCount = 0;
            this.SuccessCount = 0;
            this.pageLoadTime = 0;
            this.errorBanner = false;
        },
        async start(cach){
            //console.log(this.isAnalyze);
            if(this.isAnalyze) return;
            this.isAnalyze = true;
            this.isRedirect = false;
            this.cach = cach;
            this.page.before = this.page.input;
			this.page.desktop = null;
			this.page.mobile = null;
			this.page.errorMsg = null;
            this.domain = null;
            this.domainBefore = null;
            this.result.desktop = null;
            this.result.mobile = null;
            this.textPercent.desktop = null;
            this.textPercent.mobile = null;
            this.idpage.desktop = 0;
            this.idpage.mobile = 0;

            this.notStart = true;
            this.clearView();

            //console.log( this.page );

            if(!this.page.input || this.page.input.length<4){
                this.isAnalyze = false;
                return;
            }

            let res = await axios.post('/api/pagespeed/filter_url', {url: this.page.input });
            if(res && res.data && res.data.url){
                this.page.input = res.data.url;  //this.page.before =
                this.domainBefore = res.data.domain_before;
                this.progressBar();

                this.idpage.rand = -Math.floor(Math.random() * Math.floor(1000000));
                this.get_google(this.mode);
                setTimeout(()=>{
                    this.get_google(this.modeRev);
                    this.updateIdPage();
                },1200);

            }
            else if(res && res.data && !res.data.url){
                this.page.errorMsg = this.ll.i7680 || 'Incorrect URL';
                this.isAnalyze = false;
            }
        },
        async get_google(tMode){
            let res = await axios.post('/api/pagespeed/get', { url: this.page.input, mode:tMode, cach:this.cach, lang:this.lang, idProject:this.idProject,idpage:this.idpage });
            if(res && res.data){
                if(res.data.idpage && res.data.idpage[tMode]) this.idpage[tMode] = res.data.idpage[tMode];
                if(res.data.result){
                    if(res.data.result.lighthouseResult && res.data.result.lighthouseResult.audits){
                        this.result[tMode] = res.data.result;



                        if(tMode=='desktop'){
                            this.page.input = res.data.page; //this.page.before
                            this.domain = res.data.domain;
                            //console.log(this.page.input);

                            if(this.$route.query &&
                                ( decodeURIComponent(this.$route.query.url)!=this.page.input || this.$route.query.id )){
                                this.$router.replace({ query: {mode:this.mode, url: this.hakUrl(this.page.input)} });
                            }
                            if(res.data.datetime){
                                this.dateGenerate = res.data.datetime;
                            }
                        }
                        this.page[tMode] = res.data.page;
                        if(tMode==this.mode){
                            //console.log(this.page.before, this.page.input)
                            if(this.page.before != this.page.input) this.isRedirect = true;
                            this.scan();
                        }
                        this.notStart = false;
                    }
                    else if(res.data.result.error){
                        this.errorBanner = true;
                        this.progress = 100;
                        this.isAnalyze = false;
                        /*if(res.data.result.error.message ){
                            this.smodal.show = true;
                            this.smodal.text = res.data.result.error.message;
                            if(res.data.result.error.code){
                                this.smodal.text += "\nCode: "+res.data.result.error.code;
                            }
                        }*/

                    }
                }
            }
            if(this.page.desktop && this.page.mobile) {
                this.isAnalyze = false;
                this.progress = 100;
                let linkApiSemalt = 'https://semalt.com/api/pagespeed/dbase?ids='+this.idpage.desktop+','+this.idpage.mobile+'&nettoken=semalt&lang='+this.lang;
                let linkApiNet = 'https://reports.semalt.net/report-pagespeed/pdf/?url='+encodeURIComponent(linkApiSemalt)+'&logourl='+encodeURIComponent(this.whiteLabel.logo)+'&footer='+encodeURIComponent(this.whiteLabel.footer);
                this.linkApi = '/api/pagespeed/download?url='+encodeURIComponent(linkApiNet)+'&filename='+'Page-speed-report_'+this.domain+'_'+this.dateGenerate.substr(0,10)+'_'+this.lang+'.pdf';
            }
        },
        changeMode(tMode){
            if(!this.result[tMode] || this.isAnalyze) return;
            if(this.mode!=tMode) this.$router.replace({ query: {mode:tMode, url: this.hakUrl(this.page.input)} });
            this.mode = tMode;
            this.clearView();
            this.scan();
        },
        scan(){
            if(this.result[this.mode].lighthouseResult && this.result[this.mode].lighthouseResult.categories &&
                this.result[this.mode].lighthouseResult.categories.performance && this.result[this.mode].lighthouseResult.categories.performance.auditRefs && this.result[this.mode].lighthouseResult.categories.performance.score){
                this.percent = Math.round(this.result[this.mode].lighthouseResult.categories.performance.score*100);
                //this.percent = 91;
                if(this.percent>=90){ this.tabScore = 3; this.textPercent[this.mode] = 3; }
                else if(this.percent >= 50 && this.percent < 90){ this.tabScore = 2; this.textPercent[this.mode] = 2; }
                else { this.tabScore = 1; this.textPercent[this.mode] = 1;}

                for(let jj in this.result[this.mode].lighthouseResult.categories.performance.auditRefs){
                    if(this.result[this.mode].lighthouseResult.categories.performance.auditRefs[jj].weight>0){
                        this.auditRefsFirst.push(this.result[this.mode].lighthouseResult.categories.performance.auditRefs[jj].id);
                    }
                }
            }

            this.categ = Object.keys( this.result[this.mode].lighthouseResult.audits );
            let arr, jj, color;
            for(jj in this.categ){
                arr = this.result[this.mode].lighthouseResult.audits[this.categ[jj]];
                if(this.llg[arr.id]){
                    if(Array.isArray(this.llg[arr.id]) ){
                        arr.title = this.ll['i'+this.llg[arr.id][0]];
                        arr.description = this.ll['i'+this.llg[arr.id][1]];
                    }
                    else{
                        arr.title = this.ll['i'+this.llg[arr.id]];
                    }
                }
                let colorArr = this.detectColor(arr.score);
                color = colorArr[0];

                this.result[this.mode].lighthouseResult.audits[this.categ[jj]].scoreColor = color;
                this.$set(arr, 'scoreColor', color );
                this.$set(arr, 'dis', false );

                if(arr.id=='interactive') this.pageLoadTime = arr.displayValue;
                if(this.auditRefsFirst.includes(arr.id)){
                    this.firstArr.push(arr);
                }
                else if(arr.score>=0.9 && arr.score<=1 || this.SuccessArrKeys.includes(arr.id) ){
                    this.SuccessArr.push(arr);
                }
				else if(arr.score >= 0.5 && arr.score < 0.9){
                    this.ErrorArr.push(arr);
                }
                else if(arr.score > 0 && arr.score < 0.5){
                    this.ErrorArr.push(arr);
                }
                else if(arr.score===null){
                    this.ErrorArr.push(arr);
                }
            }
            this.ErrorCount = this.ErrorArr.length;
            this.SuccessCount = this.SuccessArr.length;

            //this.progress = 100;
            //setTimeout(()=>{this.progress = 100;},1000);
            //console.log(this.firstArr);
            //console.log(this.ErrorArr);
            //console.log(this.SuccessArr);
        },
        clickTabScan(val){
            if(this.isAnalyze || this.notStart) return;
            this.tabScan = val;
        },
        clicktabScore(val){
            if(this.isAnalyze || this.notStart) return;
            this.tabScore = val;
        },
        formatDesc(text){
            let a1=0, a2, b1, b2, name, link, link2;
            let count = (text.match(/\[/g) || []).length;
            for(let jj=0;jj<count;jj++){
                a1=a2; a2=0; b1=0; b2=0; name=''; link='';
                a1 = text.indexOf('[', a1);
                if(a1>-1){
                    a2 = text.indexOf(']', a1);
                    if(a2>-1){
                        b1 = text.indexOf('(', a2);
                        if(b1>-1){
                            b2 = text.indexOf(')', b1);
                            name = text.substr(a1+1,a2-a1-1);
                            link = text.substr(b1+1,b2-b1-1);
                            text = text.slice(0,a1) + '<a href="'+link+'" target="_blank">'+name+'</a>'  +text.slice(b2+1);
                        }
                    }
                }
                //console.log(a1, a2, b1, b2, name, link);
            }

            link2 = text.match(/\`(.*?)\`/gim);
            if(link2 && link2[0]){
                link2[0] = link2[0].replace(/`/g, '');
                text = text.replace(/\`(.*?)\`/gim, '<code>'+ this.escapeHtml(link2[0])+'</code>');
            }
            /*let link2 = text.match(/\((.*?)\)/gim);
                if(link2){
                    text = text.replace(link2[0],'');
                    link2[0] = link2[0].replace('(','').replace(')','');
                    text = text.replace(/\[(.*?)\]/gim, '<a href="'+link2[0]+'" target="_blank">$1</a>');
                }
            */
            return text;
        },
        detectColor(score){
            let color = 0;
            if(score>=0.9 && score<=1 ){
                color = 3;
            }
            else if(score >= 0.5 && score < 0.9){
                color = 2;
            }
            else if(score > 0 && score < 0.5){
                color = 1;
            }
            else if(score===null){
                color = 0;
            }
            return [color, this.color[color]];
        },
        setColor(val){
            if(val>=0 && val<4) return this.color[val];
            return '';
        },
        progressBar(){
            let timer = setTimeout(()=>{
                if(this.page.desktop && this.page.mobile) {
                    clearInterval(timer);
                    return;
                }
                let aa;
                this.progress = 0;
                this.showProgress = true;
                let interval = setInterval(()=>{
                    if(this.progress<100){
                        aa = Math.round(Math.random()*10 / 3 );
                        if(this.progress+aa > 99) this.progress = 99;
                        else this.progress += aa;
                    }
                    else{
                        this.progress = 100;
                        setTimeout(()=>{this.showProgress = false;},1000);
                        clearInterval(interval);
                    }
                },500);
            },2000);
        },
        escapeHtml(text) {
            return text
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        },
        formatSize(bytes){
            if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
            else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
            else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
            else if (bytes > 1)           { bytes = bytes + " bytes"; }
            else if (bytes == 1)          { bytes = bytes + " byte"; }
            else                          { bytes = "0 bytes"; }
            return bytes;
        },
        doCopy: function (e) {
            let selectEl = document.getElementById('copyLinkInputUrl');
            selectEl.value = window.location.href;
            selectEl.select();
            document.execCommand("copy");

            this.$refs.snotify.text(this.ll.i7724 || "Copy",1200);
        },
        formatDT(date){

            if(!date) return '';
            //26.10.2020, 11:59
            function addZero(i){
                return (i < 10)? "0" + i: i;
            }
            let aa = [3581,3582,3583,3584,3585,3586,3587,3588,3589,3590,3591,3592];
            let onedate = new Date(date.replace(' ', 'T'));
            //console.log(onedate);

            let date2 = [];
            date2[0] = onedate.getFullYear();
            if(date2[0]<2014) return null;
            date2[1] = addZero(parseInt(onedate.getMonth(),10)+1);
            date2[2] = addZero(onedate.getDate());
            date2[3] = addZero(onedate.getHours());
            date2[4] = addZero(onedate.getMinutes());
            let newdate;

            switch (this.lang){
                case 'en': newdate=(this.ll["i"+aa[parseInt(date2[1],10)-1]]||parseInt(date2[1],10))+" "+parseInt(date2[2],10)+", "+date2[0]; break;
                case 'zh': newdate = date2[0]+'年 '+parseInt(date2[1],10)+'月 '+parseInt(date2[2],10)+'日'; break;
                default: newdate=parseInt(date2[2],10)+" "+(this.ll["i"+aa[parseInt(date2[1],10)-1]]||date2[1])+" "+date2[0]; break;
            }
            newdate += ', '+date2[3]+':'+date2[4];
            return newdate;
        },
        hakUrl(url){
            if(url.indexOf('?')==-1 && url.indexOf('&')==-1) return url;
            return encodeURIComponent(url);
        },
        updateIdPage(){
            let interval = setInterval(()=>{
                if(this.page.desktop && this.page.mobile) {
                    axios.post('/api/pagespeed/updateIdPage', { idpage:this.idpage });
                    clearInterval(interval);
                }
            },500);
        },
    },
    mounted(){
        this.init();
    },
    watch:{
        lang(to, from){
            this.getll();
        },
        percent(to, from){
            this.percentC = 628.3 - (to * 628.3 / 100);
            this.percentColor = this.detectColor(to/100)[1];

            //console.log(this.detectColor(to/100), this.percentColor);
        },
        mode(to, from){
            if(to=='desktop') this.modeRev = 'mobile';
            else this.modeRev = 'desktop';
        },
        beforeComputed(to, from){
            //this.isAnalyze = false;
        }
    }
}
